<!--
  This currently is only designed for Picked, so it’s hard-coded to
  Picked-language as it’ll probably be replace with something better, maybe
  with specific account managers per-accounts or whatever, but for now...
  @TODO 2020-11-17 Replace content with satellite-agonostic languages
-->
<template>
  <div>
    <SettingsNavigation />

    <div class="max-w-screen-xl mx-auto px-6">
      <BaseCard
        class="text-center"
      >
        <div class="my-4">
          <p>We’ve made some changes recently</p>
          <p class="text-lg font-medium my-2">
            We’ll need to migrate your account to start or change a plan
          </p>
        </div>
        <div class="my-4">
          <p>
            <a
              :href="`mailto:${supportEmail}`"
              class="text-secondary font-medium"
            >
              {{ supportEmail }}
            </a>
          </p>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'

export default {
  components: {
    SettingsNavigation
  },

  data() {
    return {
      supportEmail: process.env.VUE_APP_SUPPORT_EMAIL
    }
  }
}
</script>
