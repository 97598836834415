<template>
  <BaseContainer>
    <PlanChangeSettings />
    <!-- Old plan pages: -->
    <!-- <PlanSettings v-if="usesPlan" /> -->
    <!-- <PlanDemoSettings v-else-if="usesContactForDemo" /> -->
  </BaseContainer>
</template>

<script>
import PlanChangeSettings from '@components/Settings/PlanChangeSettings'

export default {
  page: {
    title: 'Plan'
  },

  components: {
    PlanChangeSettings
  }
}
</script>
